import {
  createStylesParams,
  StyleParamType,
  wixColorParam,
  ISiteTextPreset,
  IWixStyleFont,
  wixFontParam,
} from '@wix/tpa-settings'
import {
  LIST_ALIGNMENT,
  IMAGE_SCALING,
  MOBILE_LIST_LAYOUT,
  IMAGE_RATIO,
  BUTTONS_STYLE,
} from '@wix/wix-events-commons-statics'
import {Override} from '../../commons/types/utils'
import {ISettingsNumbers, WidgetComponentTextSettings} from './settingsParams'

type IStylesNumbers = {
  cardBorderWidth: StyleParamType.Number
  listGeneralTitleFontSizeMobile: StyleParamType.Number
  listHeaderTitleFontSizeMobile: StyleParamType.Number
  borderWidth: StyleParamType.Number
  todayButtonBorderWidth: StyleParamType.Number
  todayButtonBorderRadius: StyleParamType.Number
  cardDescriptionFontSize: StyleParamType.Number
  listRoundedButtonBorderRadius: StyleParamType.Number
  listBorderWidth: StyleParamType.Number
  calendarCellBorderWidth: StyleParamType.Number
  calendarBorderWidth: StyleParamType.Number
  buttonsBorderWidth: StyleParamType.Number
  todayRoundedButtonBorderRadius: StyleParamType.Number
  listButtonBorderRadius: StyleParamType.Number
  listButtonBorderWidth: StyleParamType.Number
  cardLocationAndDateFontSize: StyleParamType.Number
  listButtonFontSizeMobile: StyleParamType.Number
  listContentTextFontSizeMobile: StyleParamType.Number
  listDateFontSizeMobile: StyleParamType.Number
  listLocationFontSizeCompactMobile: StyleParamType.Number
  listDateFontSizeCompactMobile: StyleParamType.Number
  listHeaderTitleFontSizeCompactMobile: StyleParamType.Number
  listButtonFontSizeCompactMobile: StyleParamType.Number
  calendarDetailsTitleTextSize: StyleParamType.Number
  calendarDetailsDateLocationTextSize: StyleParamType.Number
  calendarDetailsDescriptionTextSize: StyleParamType.Number
  widgetType: StyleParamType.Number
  listLayout: StyleParamType.Number
  mobileListLayout: StyleParamType.Number
  widgetLayout: StyleParamType.Number
  alignment: StyleParamType.Number
  contentAlignment: StyleParamType.Number
  listAlignment: StyleParamType.Number
  imagePosition: StyleParamType.Number
  verticalImagePosition: StyleParamType.Number
  imageGridPosition: StyleParamType.Number
  imageScaling: StyleParamType.Number
  multiImageScaling: StyleParamType.Number
  multiImageRatio: StyleParamType.Number
  /**
   * @deprecated
   */
  imageRatio: StyleParamType.Number
  listImageLayout: StyleParamType.Number
  locationAndDateFormat: StyleParamType.Number
  listLocationAndDateFormat: StyleParamType.Number
  cardsPerRow: StyleParamType.Number
  cardLocationAndDateFormat: StyleParamType.Number
  buttonsStyle: StyleParamType.Number
  buttonsBorderRadius: StyleParamType.Number
  roundedButtonsBorderRadius: StyleParamType.Number
  buttonHeight: StyleParamType.Number
  oneButtonWidth: StyleParamType.Number
  verticalDividerWidth: StyleParamType.Number
  verticalDividerHeight: StyleParamType.Number
  horizontalDividerWidth: StyleParamType.Number
  horizontalDividerHeight: StyleParamType.Number
  compactHorizontalDividerWidth: StyleParamType.Number
  compactHorizontalDividerHeight: StyleParamType.Number
  countdownMarginBottom: StyleParamType.Number
  titleMarginBottom: StyleParamType.Number
  descriptionMarginBottom: StyleParamType.Number
  dateMarginBottom: StyleParamType.Number
  locationMarginBottom: StyleParamType.Number
  dateAndLocationMarginBottom: StyleParamType.Number
  rsvpMarginBottom: StyleParamType.Number
  imageWidth: StyleParamType.Number
  imageOpacity: StyleParamType.Number
  listButtonStyle: StyleParamType.Number
  cardMinWidth: StyleParamType.Number
  cardMargins: StyleParamType.Number
  sideBySideLayoutMargins: StyleParamType.Number
  listImagePosition: StyleParamType.Number
  /**
   * @deprecated
   */
  listImageOpacity: StyleParamType.Number
  multiImageOpacity: StyleParamType.Number
  listImageWidth: StyleParamType.Number
  listDividerWidth: StyleParamType.Number
  listStripWidth: StyleParamType.Number
  calendarWeekStartDay: StyleParamType.Number
  calendarListTextSize: StyleParamType.Number
  calendarListDividerWidth: StyleParamType.Number
  todayButtonStyle: StyleParamType.Number
  recurringFilter: StyleParamType.Number
}

type IStylesBooleans = {
  showImage: StyleParamType.Boolean
  showCountdown: StyleParamType.Boolean
  showOfferRibbon: StyleParamType.Boolean
  showRecurringRibbon: StyleParamType.Boolean
  showTitle: StyleParamType.Boolean
  showDescription: StyleParamType.Boolean
  showDate: StyleParamType.Boolean
  showLocation: StyleParamType.Boolean
  showShareOptions: StyleParamType.Boolean
  showFullDividers: StyleParamType.Boolean
  showHorizontalDividers: StyleParamType.Boolean
  showCompactDividers: StyleParamType.Boolean
  showLabels: StyleParamType.Boolean
  showRSVPTitle: StyleParamType.Boolean
  showMembers: StyleParamType.Boolean
  listShowMainTitle: StyleParamType.Boolean
  listShowDate: StyleParamType.Boolean
  listShowDayOfWeek: StyleParamType.Boolean
  listShowVenueName: StyleParamType.Boolean
  listShowFullDate: StyleParamType.Boolean
  listShowLocation: StyleParamType.Boolean
  listShowDescription: StyleParamType.Boolean
  listShowSocialShare: StyleParamType.Boolean
  listShowImage: StyleParamType.Boolean
  listShowAdditionalInfo: StyleParamType.Boolean
  listShowMembers: StyleParamType.Boolean
  showSoldOutRibbon: StyleParamType.Boolean
  /**
   * @deprecated showRibbon is split into multiple other flags
   */
  showRibbon: StyleParamType.Boolean
  showRsvpClosedRibbon: StyleParamType.Boolean
  showJoinWaitlistRibbon: StyleParamType.Boolean
}

type IStylesFonts = {
  listHeaderTitleFont: StyleParamType.Font
  listHeaderLocationFont: StyleParamType.Font
  cardDescriptionFont: StyleParamType.Font
  listGeneralTitleFont: StyleParamType.Font
  calendarMonthFont: StyleParamType.Font
  calendarWeekdaysFont: StyleParamType.Font
  calendarDayNumberFont: StyleParamType.Font
  calendarCellEventNameFont: StyleParamType.Font
  listHollowButtonFont: StyleParamType.Font
  listFullButtonFont: StyleParamType.Font
  todayHollowButtonFont: StyleParamType.Font
  todayButtonFont: StyleParamType.Font
  ribbonFont: StyleParamType.Font
  listContentTextFont: StyleParamType.Font
  soldOutRibbonFont: StyleParamType.Font
  rsvpClosedRibbonFont: StyleParamType.Font
  joinWaitlistRibbonFont: StyleParamType.Font
  membershipRibbonFont: StyleParamType.Font
  recurringRibbonFont: StyleParamType.Font
  hollowButtonsFont: StyleParamType.Font
  fullButtonsFont: StyleParamType.Font
  countdownFont: StyleParamType.Font
  titleFont: StyleParamType.Font
  descriptionFont: StyleParamType.Font
  labelsFont: StyleParamType.Font
  compactTextsFont: StyleParamType.Font
  textsFont: StyleParamType.Font
}

type IStylesColors = {
  calendarCellBackground: StyleParamType.Color
  calendarBorderColor: StyleParamType.Color
  listHeaderTitleColor: StyleParamType.Color
  calendarMainActiveColor: StyleParamType.Color
  listContentTitleColor: StyleParamType.Color
  listGeneralTitleColor: StyleParamType.Color
  calendarMonthColor: StyleParamType.Color
  calendarWeekdaysColor: StyleParamType.Color
  calendarDayNumberColor: StyleParamType.Color
  calendarCellEventNameColor: StyleParamType.Color
  listHollowButtonFontColor: StyleParamType.Color
  listFullButtonFontColor: StyleParamType.Color
  todayHollowButtonColor: StyleParamType.Color
  todayButtonColor: StyleParamType.Color
  ribbonTextColor: StyleParamType.Color
  listContentTextColor: StyleParamType.Color
  listHeaderLocationColor: StyleParamType.Color
  soldOutRibbonTextColor: StyleParamType.Color
  rsvpClosedRibbonTextColor: StyleParamType.Color
  joinWaitlistRibbonTextColor: StyleParamType.Color
  membershipRibbonTextColor: StyleParamType.Color
  recurringRibbonTextColor: StyleParamType.Color
  onImageHollowButtonsFontColor: StyleParamType.Color
  hollowButtonsFontColor: StyleParamType.Color
  onImageFullButtonsFontColor: StyleParamType.Color
  fullButtonsFontColor: StyleParamType.Color
  countdownColor: StyleParamType.Color
  onImageCountdownColor: StyleParamType.Color
  titleColor: StyleParamType.Color
  onImageTitleColor: StyleParamType.Color
  onImageDescriptionColor: StyleParamType.Color
  descriptionColor: StyleParamType.Color
  onImageLabelsColor: StyleParamType.Color
  labelsColor: StyleParamType.Color
  onImageTextsColor: StyleParamType.Color
  textsColor: StyleParamType.Color
  calendarCellBorderColor: StyleParamType.Color
  calendarPastDaysBackground: StyleParamType.Color
  cardBackgroundColor: StyleParamType.Color
  cardBorderColor: StyleParamType.Color
  cardsBackgroundColor: StyleParamType.Color
  listButtonBackgroundColor: StyleParamType.Color
  listButtonBorderColor: StyleParamType.Color
  todayButtonBackgroundColor: StyleParamType.Color
  todayButtonBorderColor: StyleParamType.Color
  /**
   * @deprecated
   */
  listImageBackgroundColor: StyleParamType.Color
  multiImageBackgroundColor: StyleParamType.Color
  ribbonBackgroundColor: StyleParamType.Color
  listBackgroundColor: StyleParamType.Color
  listBorderColor: StyleParamType.Color
  listDividerColor: StyleParamType.Color
  soldOutRibbonBackgroundColor: StyleParamType.Color
  rsvpClosedRibbonBackgroundColor: StyleParamType.Color
  joinWaitlistRibbonBackgroundColor: StyleParamType.Color
  membershipRibbonBorderColor: StyleParamType.Color
  recurringRibbonBorderColor: StyleParamType.Color
  imageBackgroundColor: StyleParamType.Color
  backgroundColor: StyleParamType.Color
  borderColor: StyleParamType.Color
  onImageButtonsBorderColor: StyleParamType.Color
  buttonsBorderColor: StyleParamType.Color
  onImageButtonsBackgroundColor: StyleParamType.Color
  buttonsBackgroundColor: StyleParamType.Color
  onImageDividerColor: StyleParamType.Color
  dividerColor: StyleParamType.Color
  calendarBackgroundColor: StyleParamType.Color
  listHeaderDateColor: StyleParamType.Color
}

type ParamsMapper<Type, Val> = {
  [Property in keyof Type]: Val
}

export type WidgetStyleParams = {
  numbers: ParamsMapper<IStylesNumbers, number>
  booleans: ParamsMapper<IStylesBooleans, boolean> & {responsive: boolean; __IS_RTL__: boolean}
  colors: IStylesColors
  fonts: IStylesFonts
}

type IStyles = IStylesNumbers & IStylesBooleans & IStylesColors & IStylesFonts

export type WidgetComponentSettings = Override<IStylesNumbers & ISettingsNumbers, number> &
  Override<IStylesBooleans, boolean> & {texts: WidgetComponentTextSettings} & {responsive: boolean}

const getDefaultFont =
  (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) =>
  ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})
    return font as IWixStyleFont
  }

const customColor = (value: string) => ({
  value,
  name: null,
  opacity: 1,
})

export default createStylesParams<IStyles>({
  cardBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  listGeneralTitleFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 28
    },
  },
  listHeaderTitleFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 20
    },
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  todayButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  todayButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  cardDescriptionFontSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 15
    },
  },
  listRoundedButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 100
    },
  },
  listBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  calendarCellBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  calendarBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  buttonsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  todayRoundedButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 100
    },
  },
  listButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 0
    },
  },
  listButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 1
    },
  },
  cardLocationAndDateFontSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  listButtonFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  listContentTextFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  listDateFontSizeMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  listDateFontSizeCompactMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  listLocationFontSizeCompactMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  listHeaderTitleFontSizeCompactMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  listButtonFontSizeCompactMobile: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  calendarDetailsTitleTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 16
    },
  },
  calendarDetailsDateLocationTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  calendarDetailsDescriptionTextSize: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 14
    },
  },
  widgetType: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  mobileListLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => MOBILE_LIST_LAYOUT.FULL,
  },
  widgetLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  alignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  contentAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listAlignment: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: ({isMobile}) => (isMobile ? LIST_ALIGNMENT.LEFT : LIST_ALIGNMENT.CENTER),
  },
  imagePosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  verticalImagePosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageGridPosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageScaling: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_SCALING.CROP,
  },
  multiImageScaling: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_SCALING.CROP,
  },
  // if not set defaults to imageRatio
  multiImageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_RATIO['1:1'],
  },
  listImageLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  locationAndDateFormat: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listLocationAndDateFormat: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  cardsPerRow: {
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  },
  cardLocationAndDateFormat: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  buttonsStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => BUTTONS_STYLE.FULL,
  },
  buttonsBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  roundedButtonsBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  buttonHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  oneButtonWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  verticalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  verticalDividerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  horizontalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  horizontalDividerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  compactHorizontalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  compactHorizontalDividerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  countdownMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  titleMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  descriptionMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  dateMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  locationMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  dateAndLocationMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  rsvpMarginBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  imageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listButtonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => BUTTONS_STYLE.FULL,
  },
  cardMinWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  cardMargins: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  sideBySideLayoutMargins: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listImagePosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listImageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  multiImageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listImageWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  listDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  listStripWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  calendarWeekStartDay: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  calendarListTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  calendarListDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  todayButtonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => BUTTONS_STYLE.HOLLOW,
  },
  recurringFilter: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  showImage: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showCountdown: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showOfferRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showRecurringRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showLocation: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showShareOptions: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showFullDividers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showHorizontalDividers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showCompactDividers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showLabels: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showRSVPTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showMembers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  listShowMainTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowDayOfWeek: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  listShowVenueName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowFullDate: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowLocation: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowSocialShare: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  listShowImage: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowAdditionalInfo: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  listShowMembers: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showSoldOutRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  showRsvpClosedRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },

  showJoinWaitlistRibbon: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  calendarCellBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  calendarBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  listHeaderTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarMainActiveColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  listContentTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  listHeaderTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 26,
    }),
  },
  listHeaderLocationFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  cardDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  listGeneralTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listGeneralTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 36,
    }),
  },
  calendarMonthColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarMonthFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 18,
    }),
  },
  calendarWeekdaysColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarWeekdaysFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  calendarDayNumberColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarDayNumberFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 24,
    }),
  },
  calendarCellEventNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarCellEventNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  listHollowButtonFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  listHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  listFullButtonFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  listFullButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  todayHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  todayHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  todayButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  todayButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  ribbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#FFFFFF'),
  },
  ribbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  listContentTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listContentTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  listHeaderLocationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  soldOutRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#FFFFFF'),
  },
  soldOutRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  rsvpClosedRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#FFFFFF'),
  },
  rsvpClosedRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  joinWaitlistRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#FFFFFF'),
  },
  joinWaitlistRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  membershipRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  membershipRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  recurringRibbonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  recurringRibbonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  onImageHollowButtonsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  hollowButtonsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  onImageFullButtonsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  fullButtonsFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  hollowButtonsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  fullButtonsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  countdownFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 20,
    }),
  },
  countdownColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  onImageCountdownColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 46,
    }),
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  onImageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 15,
    }),
  },
  onImageDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  labelsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  onImageLabelsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  labelsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  compactTextsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 24,
    }),
  },
  textsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 15,
    }),
  },
  onImageTextsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  textsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  calendarCellBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  calendarPastDaysBackground: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 0.1),
  },
  cardBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  cardBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  cardsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  listButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  listButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  todayButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  todayButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  listImageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  multiImageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => undefined,
  },
  ribbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#F64D43'),
  },
  listBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  listBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  listDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  soldOutRibbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#F64D43'),
  },
  rsvpClosedRibbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#F64D43'),
  },
  joinWaitlistRibbonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => customColor('#F64D43'),
  },
  membershipRibbonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  recurringRibbonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  imageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2', 1),
  },
  onImageButtonsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  buttonsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  onImageButtonsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  buttonsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  onImageDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
  calendarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  listHeaderDateColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => undefined,
  },
})

export {customCssVars} from './custom-css-vars'
